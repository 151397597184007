<template>
  <div id="overlay" @click="off()" style="color: white; align-items: center">
    <div
      style="margin-bottom: -350px; width: 2.5rem; height: 2.5rem"
      class="spinner-grow"
      role="status"
    ></div>
  </div>

  <div style="background-color: #e8f1f5" class="wrapper fadeInDown">
    <form id="formItems" class="needs-validation" novalidate>
      <div id="formContent">
        <button
          @click="goBack"
          type="button"
          class="btn btn-sm btn-light-danger d-flex justify-content-start"
        >
          <i style="font-size: 18px" class="bi bi-arrow-left-square-fill"></i>
        </button>
        <!-- Tabs Titles -->

        <!-- Icon -->
        <div class="fadeIn first pb-5 pe-10 ps-10 pt-0">
          <img
            src="../../public/media/logos/logo_beardrug.png"
            id="icon"
            alt="User Icon"
          />
        </div>

        <div
          class="mb-5"
          style="font-size: 20px; font-weight: bold; color: #5f6379"
        >
          SYSTEM ADMINISTRATOR
        </div>

        <!-- Login Form -->
        <input
          v-model="form.username"
          type="text"
          id="login"
          ref="username"
          class="fadeIn second form-control"
          placeholder="login"
          autocomplete="off"
          required
        />
        <div class="invalid-feedback text-end pe-8 pb-3">กรุณากรอก</div>

        <div style="position: relative">
          <input
            v-model="form.password"
            @input="passwordChange(form.password)"
            :type="showPassword ? 'text' : 'password'"
            id="password"
            class="fadeIn third form-control"
            placeholder="password"
            autocomplete="off"
            maxlength="16"
            required
          />
          <button
            @click.prevent="showPassword = !showPassword"
            class="fadeIn third"
            id="btnPass"
            @mouseover="colorEyePass = 'color: white;'"
            @mouseleave="colorEyePass = 'color: grey;'"
            style="
              cursor: pointer;
              position: absolute;
              top: 30%;
              right: 10%;
              border-radius: 55%;
              border-color: transparent;
            "
          >
            <i class="far fa-eye" :style="colorEyePass"></i>
          </button>
        </div>
        <div class="row d-flex justify-space-between">
          <div class="col-4 text-start ps-14 fadeIn third">
            <span>{{ couterPass }} / 16</span>
          </div>
          <div class="col-8 text-end pe-14" style="color: #f1416c">
            <span v-if="textPassError">{{ textPassError }}</span>
          </div>
        </div>
        <div class="col-8 invalid-feedback text-end pe-8">
          <span>กรุณากรอก</span>
        </div>

        <!-- <div class="row mt-2 text-start fadeIn fourth">
          <div class="form-switch ms-13">
            <input
              v-model="showPassword"
              class="form-check-input me-2"
              type="checkbox"
              id="flexSwitchCheckDefault"
            />
            Show Password
          </div>
        </div> -->
        <input
          @click="submit(event)"
          type="submit"
          class="fadeIn fourth mt-10"
          value="Log In"
        />

        <button @click="loginGoogle" type="submit">
          <div class="row">
            <div class="col-12 text-end">
              <div class="row text-center">
                <div>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/archive/5/53/20210618182605%21Google_%22G%22_Logo.svg/120px-Google_%22G%22_Logo.svg.png"
                    width="30"
                  />
                  Sign in with Google
                </div>
              </div>
            </div>
          </div>
        </button>

        <button
          @mouseenter="changeColorAppleIcon('hover')"
          @mouseleave="changeColorAppleIcon()"
          @click="loginApple"
          class="submit-apple mb-10"
        >
          <div class="row">
            <div class="col-12 text-end">
              <div class="row text-center">
                <div>
                  <img
                    src="https://www.apple.com/v/apple-pay/o/images/overview/logo_apple__cltlfa7xve82_medium_2x.png"
                    width="25"
                    class="me-1"
                    :style="appleIconStyle"
                  />
                  Sign in with Apple
                </div>
              </div>
            </div>
          </div>
        </button>
        <!-- <input
          @click="submit(event)"
          type="submit"
          class="fadeIn fourth mb-10"
          value="Log In"
        /> -->

        <!-- Remind Passowrd -->
        <div id="formFooter">
          <a class="underlineHover" href="#">Forgot Password ?</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import loginApi from "@/api/user/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  setup() {
    document.title = "BELUCA | Login";
  },
  data: () => ({
    showPassword: false,
    isSubmit: false,
    isValid: false,

    appleIconStyle: "",

    form: {
      username: "",
      password: "",
    },
    textPassError: "",
    colorEyePass: "color: grey;",
    couterPass: 0,
  }),
  mounted() {
    this.off();
    // this.checkToken();
    this.$refs.username.focus();
  },
  methods: {
    checkToken() {
      if (this.$route.query.public === "1") {
        localStorage.clear();
      } else if (
        !this.$route.query.public === "1" ||
        this.$route.query.public !== "1"
      ) {
        document.getElementById("overlay").style.display = "block";
        const token = localStorage.getItem("belucaCodex");
        const user = localStorage.getItem("boss");
        if (token && user) {
          setTimeout(() => {
            this.$router.replace("/dashboard");
            document.getElementById("overlay").style.display = "none";
          }, 1500);
        } else {
          document.getElementById("overlay").style.display = "none";
        }
      }
    },
    async submit(event) {
      let loginResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        document.getElementById("overlay").style.display = "block";
        try {
          loginResponse = await loginApi.login.login(this.form);
        } catch (err) {
          console.log(err);
        }
        if (loginResponse.data) {
          const checkUserRole = await this.checkRole(loginResponse.data);

          if (checkUserRole) {
            localStorage.setItem(
              "belucaCodex",
              Encode.encode(loginResponse.data.token)
            );
            localStorage.setItem(
              "boss",
              Encode.encode(loginResponse.data.user.id)
            );
            localStorage.setItem(
              "role",
              Encode.encode(loginResponse.data.user.role)
            );

            localStorage.setItem(
              "companyId",
              loginResponse.data.user.companyId
            );

            Swal.fire({
              icon: "success",
              title: "เข้าสู่ระบบสำเร็จ",
              html: `<div style="font-size: 16px">สวัสดี <b style="font-size: 20px">${loginResponse.data.user.username}</b> 🥰 <br></div>`,
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              if (loginResponse.data.user.companyId) {
                this.$router.replace({
                  path: "/dashboard",
                  query: {
                    login: 1,
                  },
                });
              } else {
                this.$router.replace({
                  path: "/select-company",
                  query: {},
                });
              }
              document.getElementById("overlay").style.display = "none";
            });
          }
          document.getElementById("overlay").style.display = "none";
        } else {
          Swal.fire({
            icon: "error",
            title: "เข้าสู่ระบบไม่สำเร็จ !",
            html: `<div style="font-size: 16px">${loginResponse.message}</div>`,
            confirmButtonText: "ตกลง",
          }).then(() => {
            document.getElementById("overlay").style.display = "none";
          });
        }
      }
    },
    checkFormIsEmpty() {
      !this.form.username
        ? (this.isValid = false)
        : !this.form.password
        ? (this.isValid = false)
        : this.form.password.length < 8
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    off() {
      document.getElementById("overlay").style.display = "none";
    },
    goBack() {
      this.$router.push("/sysadmin");
    },
    passwordChange(val) {
      if (val) {
        this.couterPass = val.length;

        if (val.length < 8) {
          this.textPassError = "รหัสผ่านต้องมีความยาว 8 - 16 หลัก";
          this.isValid = false;
        } else {
          this.textPassError = "";
          this.isValid = true;
        }
      } else {
        this.couterPass = 0;
      }
    },

    loginGoogle() {
      console.log("Google");
    },

    loginApple() {
      console.log("Apple");
    },

    changeColorAppleIcon(method) {
      if (method === "hover") {
        this.appleIconStyle = "filter: invert(7);";
      } else {
        this.appleIconStyle = "";
      }
    },

    async checkRole(data) {
      if (data) {
        if (data.user.role === "SYSTEM ADMIN" || data.user.role === "ROOT") {
          return true;
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่สามารถเข้าสู่ระบบด้วย Role : ${data.user.role}`,
            html: `เข้าสู่ระบบได้ที่ Link : <a href='https://apps.beluca.co/#/public'>คลิกที่นี่</a>`,
          });
          return false;
        }
      }
    },
  },
};
</script>

<style scoped>
#btnPass:hover {
  background-color: #56baed;
}
a,
a:hover {
  color: #333;
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  /* text-transform: uppercase; */
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 100%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* text-transform: uppercase; */
  font-size: 13px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 85%;
}
button[type="submit"] {
  background-color: white;
  border: none;
  color: #39ace7;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 10px 0px;
  /* text-transform: uppercase; */
  font-size: 13px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 10px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 85%;
}

.submit-apple {
  background-color: black;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 7px 0px;
  /* text-transform: uppercase; */
  font-size: 13px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 85%;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
  transform: scale(1.05);
}

button[type="submit"]:hover {
  background-color: #e7f0fe;
  transform: scale(1.05);
}

.submit-apple:hover {
  background-color: #e7f0fe;
  color: black;

  transform: scale(1.05);
}

input[type="button"]:active,
input[type="submit"]:active,
button[type="submit"]:active,
input[type="reset"]:active {
  /* -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95); */
}

input[type="text"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="password"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

input[type="password"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="password"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, -100%, 0); */
    /* transform: translate3d(0, -100%, 0); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: none; */
    /* transform: none; */
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, -100%, 0); */
    /* transform: translate3d(0, -100%, 0); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: none; */
    /* transform: none; */
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* .fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
} */

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 1s;
}

.underlineHover:hover {
  color: #0d0d0d;
  transform: scale(1.1);
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}
</style>
